<template>
    <div></div>
</template>

<script>
    export default {

        components: {
        },

        data() {
            return {};
        },

        methods: {
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
        },

        mounted() {
        },

        beforeUpdate() {
        },

        updated() {
        },

        beforeDestroy() {
        },

        destroyed() {
        },

    };
</script>
