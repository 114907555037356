// [[ 라이브러리 import ]]
import Vue from "vue";
import VueRouter from "vue-router";

// [[ js import ]]
import { store } from "@/vuex/store";
import common from "@/common/js/common.js";
import App from "@/App.vue";

Vue.use(VueRouter);
Vue.use(common);
Vue.config.productionTip = false;

// --------------------------------------[[ comp 및 inc 설정 시작 ]]------------------------------------------- //

// [[ comp 및 inc 조회 ]]
const comps = require.context(
    "@/vues", // 검색 시작폴더
    true, // 하위 폴더까지 포함할 지 여부
    /.+?.vue$/ // 정규식
);

// [[ comp 키값 설정 ]]
let keysComps = comps.keys();

// [[ comp 정보 설정 ]]
for (let idx = 0; idx < keysComps.length; idx++) {

    let key = keysComps[idx];
    let name = key.split("/").pop().replace(/\.\w+$/, '')

    // include 제외한 vue
    if (key.indexOf("/_component/") > -1
        || key.indexOf("/_include/") > -1) {

        let config = comps(key);

        Vue.component(
            name,
            config.default || config
        );
    }
}

// --------------------------------------[[ comp 및 inc 설정 종료 ]]------------------------------------------- //

// --------------------------------------[[ router 설정 시작 ]]------------------------------------------- //

// [[ vues 조회 ]]
const vues = require.context(
    "@/vues", // 검색 시작폴더
    true, // 하위 폴더까지 포함할 지 여부
    /.+?.vue$/ // 정규식
);

// [[ vues 키값 설정 ]]
let keysVues = vues.keys();

// [[ routes 경로 설정 ]]
let routes = [];
for (let idx = 0; idx < keysVues.length; idx++) {

    let key = keysVues[idx];
    let path = key.replace("./", "/").replace(".vue", "");

    // include 제외한 vue
    if (key.indexOf("/_component/") == -1
        && key.indexOf("/_include/") == -1) {

        let config = vues(key);
        let ob = {};
        ob.name = "";
        ob.path = path;
        ob.component = config.default || config;
        ob.children = [];

        routes.push(ob);
    }
}

// [[ index 경로 설정 ]]
import index from "@/vues/index.vue";
routes.push({ path: "/", component: index });
routes.push({ path: "*", component: index });

// [[ router 설정 ]]
const router = new VueRouter({
    mode: "history",
    routes,
});

// --------------------------------------[[ router 설정 종료 ]]------------------------------------------- //

// [[ vue 설정 ]]
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
