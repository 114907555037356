<template>
    <div id="app">

        <!-- route -->
        <router-view :key="$route.fullPath"></router-view>
        <!-- // route -->

    </div>
</template>

<script>
    export default {
        components: {},

        data() {
            return {};
        },

        methods: {

            // 진입점
            init() { },

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
        },

        mounted() {
        },

        beforeUpdate() {
        },

        updated() {
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
